import {
  PAYMENT_METHOD,
  DEFAULT_HOME_PAGE
} from "./constants"; /*
 This is the refactored version for handling  redirections flows for eNets, UnionPay or
 any new payment methods that we develop.

*/

/*
  The below methods are used for redirection to merchant page for the
  registered successRedirectUrl & failureRedirectUrl for a given transaction.
  This is written to avoid the deduplication of writing events to register &
  deregister for a component to handle the redirection scnearios.
*/

const allowedRedirections = [PAYMENT_METHOD.CC, PAYMENT_METHOD.AMEX_CC];

/*
  isRedirectionAllowed returns true if the paymentType is not CC flow
  ex: CC, AMEX_CC 
  For other paymentType it returns false. 
*/

export const isRedirectionAllowed = () => {
  let paymentType = sessionStorage.getItem("paymentType");
  if (paymentType) {
    return !allowedRedirections.includes(paymentType);
  }
  return false;
};

export const handleRedirectToMerchant = () => {
  try {
    sessionStorage.removeItem("modelData");
    let successRedirectUrl = sessionStorage.getItem("successRedirectUrl");
    successRedirectUrl && window.location.assign(successRedirectUrl);
  } catch (e) {
    console.log(e);
  }
};

export const handleRedirectToMerchantFailure = () => {
  try {
    sessionStorage.removeItem("modelData");
    let failureRedirectUrl = sessionStorage.getItem("failureRedirectUrl");
    failureRedirectUrl
      ? window.location.assign(failureRedirectUrl)
      : window.location.assign(DEFAULT_HOME_PAGE);
    clearSessionStorage();
  } catch (e) {
    console.log(e);
  }
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const redirectToMerchantFailureWithErrorDetail = ({
  failureRedirectUrl = false,
  errorDetail = false
}) => {
  if (errorDetail && failureRedirectUrl) {
    window.location.assign(`${failureRedirectUrl}${errorDetail}`);
  }
  if (failureRedirectUrl) {
    window.location.assign(`${failureRedirectUrl}`);
  }
};
