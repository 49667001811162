import { css } from "styled-components";

export const createMediaQueries = (breakPoints) => {
  return Object.keys(breakPoints).reduce((acc, label) => {
    acc[label] = (...args) =>
      css`
        @media (min-width: ${breakPoints[label]}) {
          ${css(...args)}
        }
      `.join("");
    return acc;
  }, {});
};

const breakpoints = {
  sm: "48em", // ~768px tablet
  md: "62em", // ~992px desktop
  lg: "75em", // ~1200px large desktop
  xl: "100em" // ~1600px
};

const media = createMediaQueries(breakpoints);

export default media;
