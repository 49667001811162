import React from "react";
import { Text, TextLink, Selector } from "@dls/web";
import ccIcon from "@dls/assets/images/payment_generic_credit_card@3x.png";
import eNetsIcon from "@dls/assets/images/payment_enets@3x.png";
import dashIcon from "@dls/assets/images/payment_dash@3x.png";
import unionPayIcon from "@dls/assets/images/payment_unionpay@3x.png";
import payNowIcon from "../../assets/PayNow@3x.png";
import eGiroIcon from "../../assets/eGiro.png";

import VISA from "@dls/assets/images/payment_visa_dark@3x.png";
import MASTERCARD from "@dls/assets/images/payment_mastercard_dark@3x.png";
import AMEX from "@dls/assets/images/payment_amex@3x.png";
import { SelectContainer, StyleLink } from "./styles";
import { noop } from "@lux/helpers";
const BNPL = "https://www.atome.sg/app-logo.png";

const PaymentSelectBox = (props) => {
  const iconsList = {
    ccIcon,
    eNetsIcon,
    dashIcon,
    unionPayIcon,
    payNowIcon,
    VISA,
    MASTERCARD,
    AMEX,
    BNPL,
    eGiroIcon
  };
  const iconSrc = iconsList[props.icon] || "";
  const {
    removeLink = false,
    removeHandler = noop,
    removeLinkText = "",
    selected,
    disabled,
    onClick,
    detail,
    heading,
    icon,
    expiredText = ""
  } = props;

  return (
    <>
      <SelectContainer bottom={0.25}>
        <Selector
          alignment="row"
          selected={selected}
          onClick={onClick}
          disabled={disabled}
          data-testid="select-box"
        >
          <Selector.Body title={heading} align={"start"} subtitle={detail} />
          <Selector.Image src={iconSrc} alt={icon} />
        </Selector>
      </SelectContainer>
      {removeLink && (
        <Text
          type="smallBody"
          onClick={removeHandler}
          data-testid="remove-card"
        >
          {disabled && expiredText}
          <StyleLink type="link">{removeLinkText}</StyleLink>
        </Text>
      )}
    </>
  );
};

export default PaymentSelectBox;
