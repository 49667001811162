import React from "react";
import { Text, Button, Grid, Row, Column } from "@dls/web";
import { handleRedirectToMerchantFailure } from "./../utils/redirections";
import { StyledMainRow } from "./../common/styles";
import styled from "styled-components";

const StyledButtonRow = styled(Row)`
  pading-top: 24px;
`;

const GenericError = () => (
  <Grid>
    <StyledMainRow center={"xs"}>
      <Column>
        <Text type={"pageTitle"}>Something went wrong</Text>
      </Column>
    </StyledMainRow>
    <Row center={"xs"}>
      <Column>
        <Text type={"body"}>
          We are working to fix this. Please try again later.
        </Text>
      </Column>
    </Row>
    <StyledButtonRow center={"xs"}>
      <Column>
        <Button secondary onClick={() => handleRedirectToMerchantFailure()}>
          Back to Home
        </Button>
      </Column>
    </StyledButtonRow>
  </Grid>
);

export default GenericError;
