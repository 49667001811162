import { Spacing, Text } from "@dls/web";
import styled from "styled-components";

export const SelectContainer = styled(Spacing)`
  height: 5.5rem;
`;

export const StyleLink = styled(Text)`
  cursor: pointer;
  color: #0062F5;
  font-size:1rem;
  display:inline;
`