import React from "react";
import { Button, Modal } from "@dls/web";
import { noop } from "@lux/helpers";

const PaymentModal = (props) => {
  const { visible, title, content, btnText, onClick, onClose } = props;
  return (
    <Modal
      visible={visible}
      title={title}
      onClose={onClose}
      data-testid="payment-modal"
    >
      <Modal.Content>
        {content}
        <Button
          fullWidth
          onClick={onClick}
          data-testid="remove-card-proceed-btn"
        >
          {btnText}
        </Button>
      </Modal.Content>
    </Modal>
  );
};

PaymentModal.defaultProps = {
  title: "",
  content: "",
  btnText: "",
  onClick: noop,
  onClose: noop,
  visible: false
};

export default PaymentModal;
