import React from "react";
import Header from "./../Header";
import Footer from "../Footer";
import styled from "styled-components";

const LayoutMainContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledLayoutChildren = styled.div`
  flex: "1 0 auto";
`;

const Layout = ({ children, footer = true }) => {
  return (
    <LayoutMainContainer>
      <Header />
      <StyledLayoutChildren>{children}</StyledLayoutChildren>
      {footer && <Footer />}
    </LayoutMainContainer>
  );
};

export default Layout;
