import React from "react";
import { Column, Notification } from "@dls/web";

const InlineErrorMessage = ({ message }) => {
  return (
    <Column xs={12} md={6}>
      <Notification type="alert" content={message} />
    </Column>
  );
};

export default InlineErrorMessage;