import React, { createContext, useState } from "react";
import en from "./../locale/en.json";
export const LocaleContext = createContext();

export const LocaleProvider = ({ children }) => {
  const [locale] = useState(en);
  return (
    <LocaleContext.Provider
      value={{
        locale
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
