import React, { createContext, useState, useEffect } from "react";
import {
  getToken,
  redirectToErrorPage,
  isObject,
  isPaymentSuccessRoute,
  getCurrentPageUrl
} from "./../utils/helpers";
import { getUrlParams } from "@lux/helpers";

export const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [summaryEmail, setSummaryEmail] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("");
  const [oauthToken, setOauthToken] = useState(null);

  const [manageCardsInfo, setManageCardsInfo] = useState({
    txnType: "payment",
    operationsAllowed: [],
    manageCardsInstrumentInfo: []
  });

  useEffect(() => {
    async function invokeOauthToken() {
      const pageUrl = getCurrentPageUrl();
      let queryParams = pageUrl
        ? getUrlParams(pageUrl)
        : { paymentToken: null, correlationId: null };
      let paymentQP = sessionStorage.getItem("paymentQP");

      if (!isPaymentSuccessRoute() && paymentQP) {
        queryParams = getUrlParams(paymentQP);
      }

      if (isObject(queryParams) && Object.keys(queryParams).length) {
        const { paymentToken, correlationId } = queryParams;
        if (paymentToken && correlationId) {
          try {
            const token = await getToken({ paymentToken, correlationId });
            if (token) {
              setOauthToken(token);
            }
          } catch (e) {
            redirectToErrorPage();
            sessionStorage.removeItem("paymentQP");
          }
        }
      }
    }
    /* istanbul ignore next */
    if (!oauthToken) invokeOauthToken();
  }, []);

  const saveSummaryEmail = (email) => {
    setSummaryEmail(email);
  };

  const savePaymentInfo = (payment) => {
    setPaymentInfo(payment);
    sessionStorage.setItem("successRedirectUrl", payment.successRedirectUrl);
    sessionStorage.setItem("failureRedirectUrl", payment.failureRedirectUrl);
  };

  const savePaymentMethod = (method) => {
    setPaymentMethod(method);
    sessionStorage.setItem("paymentType", method);
  };

  return (
    <PaymentContext.Provider
      value={{
        summaryEmail,
        saveSummaryEmail,
        paymentInfo,
        savePaymentInfo,
        paymentMethod,
        savePaymentMethod,
        manageCardsInfo,
        setManageCardsInfo,
        oauthToken
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;
