import React, { useState, useEffect, useRef } from "react";

const UnionPayWindow = () => {
  const [txnReq] = useState(() => {
    const modelData = JSON.parse(sessionStorage.getItem("modelData"));
    return modelData.txnReq && modelData.txnReq.msg;
  });
  const [unionpayUrl] = useState(() => {
    const modelData = JSON.parse(sessionStorage.getItem("modelData"));
    return modelData.unionpayUrl;
  });

  const formRef = useRef(null);

  useEffect(() => {
    if (txnReq && unionpayUrl) {
      formRef.current.submit();
    }
  }, [txnReq, unionpayUrl]);

  return (
    <form
      ref={formRef}
      name="unionPayForm"
      id="unionPayForm"
      action={unionpayUrl}
      method="POST"
      acceptCharset="UTF-8"
      data-testid="unionPayForm"
    >
      {txnReq &&
        Object.entries(txnReq).map(([key, value]) => {
          return (
            <input type="hidden" name={key} id={key} key={key} value={value} />
          );
        })}
    </form>
  );
};

export default UnionPayWindow;
