import styled from "styled-components";
import { Grid, Column, Row, Divider } from "@dls/web";
import media from "./../utils/media";
import { web } from "@dls/themes";
const theme = web.dlsStandard1;

export const StyledMainRow = styled(Row)`
  padding-top: 5rem;
`;

export const StyledCommonColumn = styled(Column)`
  padding: 0;
`;

export const StyledGridContainer = styled(Grid)`
  margin-top: 1.5rem;
  ${media.sm`  
     padding-top: 3.5rem;
  `}
`;

export const OverFlowContainer = styled.div`
  overflow: auto;
`;

export const ColumnWithNoPadding = styled(Column)`
  padding: 0;
`;

export const StyledDivider = styled(Divider)`
  padding-top: 2.5rem;
`;

export const RowWithPaddingBotttom = styled(Row)`
  padding-bottom: 0.75rem;
`;

export const RowWithMediumPaddingBottom = styled(Row)`
  padding-bottom: 1.5rem;
`;

export const RowWithLargePaddingBottom = styled(Row)`
  padding-bottom: 2.5rem;
`;

export const PaymentTotal = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 32px;
`;

export const PaymentMethodsContainer = styled(Row)`
  padding-bottom: 1rem;
`;

export const SelectionContainer = styled(Column)`
  @media (max-width: ${theme.brk_sm}) {
    padding: 4px 0;
  }
  @media (min-width: ${theme.brk_sm}) {
    &:nth-child(odd) {
      padding: 4px 8px 4px 0;
    }
    &:nth-child(even) {
      padding: 4px 0 4px 8px;
    }
  }
`;

export const MobileButtonContainer = styled(Row)`
  display: flex;
  width: 100%;
`;

export const AlignCenteredRow = styled(Row)`
  justify-content: center;
  text-align: center;
`;
