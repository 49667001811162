import React from "react";
import { Text } from "@dls/web";
import { theme } from "@dls/web";
import styled from "styled-components";
import media from "../../utils/media";

const FooterWrapper = styled.footer`
  padding-top: 64px;
  padding-bottom: 24px;
  ${media.sm`  
    padding-top: 120px;
    padding-bottom: 40px;
  `}
  margin-top:auto;
`;

const FooterLink = styled.span`
  display: inline-block;
  & + &:before {
    content: " ";
    display: inline-block;
    width: 1px;
    height: 16px;
    margin: -3px ${theme.space[4]}px;
    background: ${theme.main.colours.divider};
  }

  a {
    letter-spacing: -0.3px;
  }
`;

const FooterText = styled(Text)`
  text-align: center;
  letter-spacing: -0.2px;
`;

const FooterLinksContainer = styled.div`
  text-align: center;
`;

FooterText.defaultProps = {
  type: "body"
};

const footerLinkData = [
  {
    text: "Contact Us",
    link: "https://www.singtel.com/contact-us"
  },
  {
    text: "Data protection",
    link: "https://www.singtel.com/data-protection"
  },
  {
    text: "Terms of use",
    link: "https://www.singtel.com/standard-agreement"
  }
];

export default function Footer() {
  return (
    <FooterWrapper>
      <FooterText>
        &copy; Singtel (CRN: 199201624D) All Rights Reserved.
      </FooterText>
      <FooterLinksContainer>
        {footerLinkData.map((item) => (
          <FooterLink key={item.text}>
            <Text type="link" href={item.link} target="_blank">
              {item.text}
            </Text>
          </FooterLink>
        ))}
      </FooterLinksContainer>
    </FooterWrapper>
  );
}
