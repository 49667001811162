import React, { Suspense, lazy } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { ROUTEPREFIX } from "./utils/constants";
import { UnionPayWindow } from "./containers";

import { GenericError, RouteWithLayout, Layout } from "./components";
import PaymentProvider from "./context/paymentContext";
import LocaleProvider from "./context/localeContext";
import ScrollToTop from "./components/ScrollToTop";

const PaymentSummary = lazy(() => import("./containers/PaymentSummary"));
const CreditCardPayment = lazy(() => import("./containers/CreditCardPayment"));
const PaymentStatus = lazy(() => import("./containers/PaymentStatus"));
const PaymentError = lazy(() => import("./containers/PaymentError"));
const PaymentSelection = lazy(() => import("./containers/PaymentSelection"));
const SavedCcPayment = lazy(() => import("./containers/SavedCcPayment"));
const EnetsPayment = lazy(() => import("./containers/EnetsPayment"));
const EGIROPage = lazy(() => import("./containers/EGIRO"));
const ManageInstruments = lazy(() => import("./containers/ManageInstruments"));
const RedirectSuccess = lazy(() => import("./containers/RedirectSuccess"));
const Paynow = lazy(() => import("./containers/Paynow"));

const EnetsWindowWithRouter = lazy(() =>
  import("./containers/EnetsPayment/EnetsWindow")
);
import { ThemeProvider } from "@dls/web";
import { web } from "@dls/themes";

const theme = web.dlsStandard;

const reload = () => window.location.reload();

const routes = (
  <Router basename={ROUTEPREFIX}>
    <Suspense fallback={<div>Loading...</div>}>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <LocaleProvider>
          <PaymentProvider>
            <Switch>
              <Route path="/paymentsummary" exact>
                <PaymentSummary />
              </Route>
              <Route
                path="/paymentselection"
                exact
                component={PaymentSelection}
              />
              <Route
                layout={Layout}
                path="/savedcc"
                exact
                component={SavedCcPayment}
              />
              {/* Redirect while preserving query params*/}
              <Route
                path="/paymentmethod"
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: location.pathname.replace(
                        /paymentmethod/,
                        "creditcard"
                      )
                    }}
                  />
                )}
              />
              <Route path="/creditcard" exact>
                <CreditCardPayment />
              </Route>
              <RouteWithLayout
                layout={Layout}
                path="/managecard"
                component={ManageInstruments}
              />
              <RouteWithLayout
                path={["/enetspay", "/bnpl", "/unionpay"]}
                exact
                layout={Layout}
                component={EnetsPayment}
              />
              <RouteWithLayout
                path="/egiro"
                layout={Layout}
                component={EGIROPage}
              />
              <RouteWithLayout
                layout={Layout}
                path="/paynow"
                component={Paynow}
              />
              <Route path="/paymentsuccess" exact>
                <PaymentStatus />
              </Route>
              <Route path="/redirectsuccess" exact>
                <RedirectSuccess />
              </Route>

              <Route exact path={["/paymenterror", "/managecarderror"]}>
                <PaymentError />
              </Route>
              <RouteWithLayout
                layout={Layout}
                path="/errorpage"
                component={GenericError}
                alignEntrust={"center"}
              />
              <Route path="/enetswindow" exact>
                <EnetsWindowWithRouter />
              </Route>
              <Route path="/unionpaywindow" exact>
                <UnionPayWindow />
              </Route>
              <Route path="/acs_v1.html" onEnter={reload} />
              <RouteWithLayout
                layout={Layout}
                component={GenericError}
                alignEntrust={"center"}
              />
            </Switch>
          </PaymentProvider>
        </LocaleProvider>
      </ThemeProvider>
    </Suspense>
  </Router>
);

export default routes;
